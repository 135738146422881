const urlKo = {
  tips: 'https://help.typecast.ai/ko/collections/3703017-%EC%82%AC%EC%9A%A9%EB%B2%95',
  help: 'https://help.typecast.ai/ko',
  terms_of_use: 'https://help.typecast.ai/ko/articles/5361255-%EC%9D%B4%EC%9A%A9%EC%95%BD%EA%B4%80',
  privacy_policy:
    'https://help.typecast.ai/ko/articles/5361327-%EA%B0%9C%EC%9D%B8%EC%A0%95%EB%B3%B4-%EC%B2%98%EB%A6%AC%EB%B0%A9%EC%B9%A8',
  open_kakao: 'https://open.kakao.com/o/gmn4ndzb',
  neosapeince: 'https://neosapience.com/about/',
  recruitment: 'https://www.notion.so/9e4a3036a8884d4589bdfab004d0ee2c',
  news: 'https://neosapience.com/news/',
  facebook: 'https://www.facebook.com/typecastkr',
  youtube: 'https://www.youtube.com/channel/UCMdKT9YjU_c8_FN88c4ZPwg',
  linkedin: 'https://www.linkedin.com/company/neosapience',
  medium: 'https://medium.com/typecast-ai',
  actor_request_free: 'https://forms.gle/dqNUzCnpPNHm6SLZ7',
  actor_request_paid: 'https://forms.gle/YvAWjhXE4TQiiGrW9',
  about_us: 'https://neosapience.com/about/',
  pro_change_announcement: 'https://www.notion.so/neosapience/5acd5c76d7234acf89a567b7e064f9a1',
  beta_tester_recruit:
    'https://docs.google.com/forms/d/e/1FAIpQLSd4LvibhlirUikR3n6NYSZEjuguv4qqZgIFV5WjSFqFX8-4Mw/viewform',
  personal_information_agreement: 'https://help.typecast.ai/ko/articles/5372688-개인정보-수집-및-이용-동의서',
  virtual_human: 'https://neosapience.notion.site/1975e7766c924b58b721ea2a35d3ba0c',
  faq: 'https://help.typecast.ai/ko/collections/3703014-%EC%9E%90%EC%A3%BC-%EB%AC%BB%EB%8A%94-%EC%A7%88%EB%AC%B8',
  video_feedback: 'https://forms.gle/VL4rxcp5q3H5oqCd8',
  video_group_chat: 'https://open.kakao.com/o/gdhzY4md',
  fhd_video: 'https://neosapience.notion.site/FHD-2b58b3cfbe4b454dbe6cc1261bd266c9',
  discord: 'https://discord.com/invite/7xMpRr2BCD',
  start_typecast: 'https://www.youtube.com/embed/kXtDIwvsgwU',
  new_editor: 'https://youtu.be/kXtDIwvsgwU?t=22',
  new_membership: 'https://www.notion.so/neosapience/51ff3069a60342f0ade192b6fcdcf77a',
  home_contact: '/kr/contact?lang=kr',
  home_character: '/kr/character?lang=kr',
  home_guideline: '/kr/guideline?lang=kr',
  home_policy: '/kr/policy?lang=kr',
  pipedrive: 'https://webforms.pipedrive.com/f/6qn62LzpKTUDfsnxPHID8BUnnRNC2APQbDJ9yhKI2Qi4U8whpNDGpqgXpflcOOe4Lh',
  pipedrive_banner:
    'https://webforms.pipedrive.com/f/c6zda8h34rXTS2L6J7ZwcqxI7KP8RBsmMj1vPFyU57QByx4R2KCkgP8qx1WJYzkoMz',
  how_to_use_shared_project: 'https://neosapience.notion.site/61dcf1f49a3f4e5fb6876bae813e4a18',
  blog_wordpress: 'https://typecast.ai/kr/learn/wp-json/wp/v2/posts?per_page=4&categories=27&context=embed&page=1',
  blog: 'https://typecast.ai/kr/learn/',
  ta_notion: 'https://neosapience.notion.site/shut-down-virtual-human',
}

export default urlKo
