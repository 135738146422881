import isEmpty from 'lodash-es/isEmpty'
import backend from '@/backend/backend-api'
import {getParsedPlanList} from '../../model/membership-plan'
import {
  getPaymentFailure,
  closePaymentFailureBanner,
  deletePaymentFailureCookie,
  setFalsePaymentFailureCookie,
  getSelectedCouponPlan,
} from '@/utils/payment'
import store from '@/store'
import router from '@/router.js'

const state = {
  selectedPlan: {},
  paymentReceiptUrl: '',
  loading: false,
  error: null,
  showPaymentFailure: false,
  showPaymentFailureBanner: false,
  planList: {},
  showPlanFullpageModal: false,
  businessInfo: null,
  couponItem: {},
  showCouponItemModal: false,
}

// getters
const getters = {
  planList: state => state.planList,
  selectedPlan: state => state.selectedPlan,
  paymentReceiptUrl: state => state.paymentReceiptUrl,
  loading: state => state.loading,
  error: state => state.error,
  businessInfo: state => state.planList.business_id,
  isPrepaidPlan: state => state.selectedPlan.name === 'enterprise_id',
}

// actions
const actions = {
  async reqPlanList({state, commit}, version = 'v3') {
    if (state.loading || !isEmpty(state.planList)) {
      return
    }
    commit('setLoading', true)
    const $http = this._vm.$typecast.$http
    try {
      const planList = await backend.getSubscribePlan($http, version)
      const newPlanList = getParsedPlanList(planList, version)
      delete newPlanList.enterprise_id
      commit('setPlanList', newPlanList)
    } catch (error) {
      commit('setLoading', false)
      commit('setError', error)
    }
  },
  async reqCouponList({state, commit, dispatch}, payload = {page: 1}) {
    const uid = store.getters['typecast/user/uid']
    if (!uid) {
      return
    }
    if (!state.planList.hasOwnProperty('free')) {
      await dispatch('reqPlanList')
    }
    commit('setLoading', true)
    const $http = this._vm.$typecast.$http
    try {
      const {
        page: {end_page: endPage},
        result,
      } = await backend.getCouponList($http, {user_id: uid, page: payload.page})

      // 쿠폰 목록이 비어있는 경우 처리
      if (!result.length) {
        commit('setCouponItem', {})
        commit('setLoading', false)
        return false
      }

      // 사용 가능한 쿠폰 찾기
      const availableCoupon = result.find(item => item.can_be_used)

      // 현재 페이지에 사용 가능한 쿠폰이 없고 다음 페이지가 있는 경우
      if (!availableCoupon && endPage > 1 && payload.page < endPage) {
        return await dispatch('reqCouponList', {page: payload.page + 1})
      }

      // 쿠폰 상태 업데이트
      commit('setCouponItem', availableCoupon || {})

      const {query} = router.currentRoute
      if (query.hasOwnProperty('code') && query.code) {
        return
      }
      const hasValidCoupon = !isEmpty(availableCoupon)
      if (hasValidCoupon) {
        const selectedPlan = getSelectedCouponPlan({
          couponItem: availableCoupon,
          planList: state.planList,
          paymentCurrency: store.getters['typecast/user/paymentCurrency'],
        })
        commit('setSelectedPlan', selectedPlan)
      }
      return hasValidCoupon
    } catch (error) {
      commit('setLoading', false)
      commit('setError', error)
    } finally {
      commit('setLoading', false)
    }
  },
  async getCouponItem({state, commit, dispatch}, {oid}) {
    if (!oid) {
      return
    }
    if (!state.planList.hasOwnProperty('free')) {
      await dispatch('reqPlanList')
    }
    commit('setLoading', true)
    const $http = this._vm.$typecast.$http
    try {
      const {result} = await backend.getCouponItem($http, {oid})
      commit('setCouponItem', result)
      if (!isEmpty(result)) {
        const selectedPlan = getSelectedCouponPlan({
          couponItem: result,
          planList: state.planList,
          paymentCurrency: store.getters['typecast/user/paymentCurrency'],
        })
        commit('setSelectedPlan', selectedPlan)
      }
      commit('setLoading', false)
    } catch (error) {
      commit('setLoading', false)
      commit('setError', error)
    }
  },
}

const mutations = {
  setPlanList(state, list) {
    state.planList = list
    state.loading = false
    state.error = false
  },
  setSelectedPlan(state, plan) {
    state.selectedPlan = plan
  },
  setPaymentReceiptUrl(state, value) {
    state.paymentReceiptUrl = value
  },
  setLoading(state, value) {
    state.loading = value
  },
  setError(state, value) {
    state.error = value
  },
  setShowPaymentFailure(state, me) {
    if (!me) {
      state.showPaymentFailure = false
      state.showPaymentFailureBanner = false
      return
    }
    state.showPaymentFailure = getPaymentFailure(me)
    state.showPaymentFailureBanner = getPaymentFailure(me, 'closed')
  },
  closeShowPaymentFailureBanner(state, me) {
    closePaymentFailureBanner(me)
    state.showPaymentFailureBanner = false
  },
  closeShowPaymentFailure(state, {me, force}) {
    if (!state.showPaymentFailure) {
      return
    }
    if (force) {
      state.showPaymentFailure = false
      state.showPaymentFailureBanner = false
      deletePaymentFailureCookie(me)
      return
    }
    state.showPaymentFailure = false
    state.showPaymentFailureBanner = false
    setFalsePaymentFailureCookie(me)
  },
  setShowPlanFullpageModal(state) {
    state.showPlanFullpageModal = !state.showPlanFullpageModal
  },
  setCouponItem(state, item = {}) {
    state.couponItem = item
  },
  setShowCouponItemModal(state, showCouponItemModal) {
    state.showCouponItemModal = showCouponItemModal
  },
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
}
