import Vue from 'vue'
import Router, {isNavigationFailure, NavigationFailureType} from 'vue-router'
import intercomManager from '@/utils/intercomManager'
import {i18n, loadLanguageAsync, LANGUAGE} from './i18n'
import {useBackDownloadStore} from 'store/back-download'
import {initCookieScript} from './cookie-banner'

const EmptyComponent = () => import(/* webpackChunkName: "empty-page" */ './views/EmptyPage.vue')
const NewLoginPage = () => import(/* webpackChunkName: "new-login-page" */ './views/NewLoginPage.vue')
const RegisterPage = () => import(/* webpackChunkName: "register-page" */ './views/RegisterPage.vue')
const EditorCreate = () => import(/* webpackChunkName: "editor-create" */ './views/editor/Create.vue')
const Dashboard = () => import(/* webpackChunkName: "dashboard" */ './views/v3/Dashboard.vue')
const MyProjectPage = () => import(/* webpackChunkName: "my-project-page" */ './views/v3/MyProjectPage.vue')
const SharedProjectPage = () => import(/* webpackChunkName: "shared-project-page" */ './views/v3/SharedProjectPage.vue')
const SettingsPage = () => import(/* webpackChunkName: "settings-page" */ './views/v3/SettingsPage.vue')
const SettingsMembersPage = () =>
  import(/* webpackChunkName: "settings-members-page" */ './views/v3/SettingsMembersPage.vue')
const SettingsGeneralPage = () =>
  import(/* webpackChunkName: "settings-general-page" */ './views/v3/SettingsGeneralPage.vue')
const SettingsAccountPage = () =>
  import(/* webpackChunkName: "settings-account-page" */ './views/v3/SettingsAccountPage.vue')
const SettingsYoutubePage = () =>
  import(/* webpackChunkName: "settings-youtube-page" */ './views/v3/SettingsYoutubePage.vue')
const SettingsIpWhitelistsPage = () =>
  import(/* webpackChunkName: "settings-ip-whitelists-page" */ './views/v3/SettingsIpWhitelistsPage.vue')
const SettingsDataRetentionPage = () =>
  import(/* webpackChunkName: "settings-data-retention-page" */ './views/v3/SettingsDataRetentionPage.vue')
const PlanPage = () => import(/* webpackChunkName: "plan-page" */ './views/v3/PlanPage')
const PlanSubscriptionPage = () =>
  import(/* webpackChunkName: "plan-subscription-page" */ './views/v3/PlanSubscriptionPage')
const PlanPaymentBlockPage = () =>
  import(/* webpackChunkName: "plan-payment-block-page" */ './views/v3/PlanPaymentBlockPage')
const PlanInvoicesPage = () => import(/* webpackChunkName: "plan-invoices-page" */ './views/v3/PlanInvoicesPage')
const PlanDownloadHistoryPage = () =>
  import(/* webpackChunkName: "plan-download-history-page" */ './views/v3/PlanDownloadHistoryPage')
const PrepaidPlanPurchasePage = () =>
  import(/* webpackChunkName: "prepaid-plan-purchase-page" */ './views/v3/PrepaidPlanPurchasePage')
const SecessionPage = () => import(/* webpackChunkName: "secession-page" */ './views/v3/SecessionPage')
const SecessionResultPage = () =>
  import(/* webpackChunkName: "secession-result-page" */ './views/v3/SecessionResultPage')
const TutorialPage = () => import(/* webpackChunkName: "tutorial-page" */ './views/v3/TutorialPage')
const SampleTemplatesPage = () =>
  import(/* webpackChunkName: "sample-templates-page" */ './views/v3/SampleTemplatesPage')
const InactivationPage = () => import(/* webpackChunkName: "inactivation-page" */ './views/v3/InactivationPage')
const PortalPage = () => import(/* webpackChunkName: "portal-page" */ './views/PortalPage')
const EditorContainer = () => import(/* webpackChunkName: "editor-container" */ '@/components/editor/EditorContainer')
const ActorSelectionModal = () =>
  import(/* webpackChunkName: "actor-selection-modal" */ '@/components/character-casting/ActorSelectionModal')
const CustomIconPage = () => import(/* webpackChunkName: "custom-icon-page" */ '@/views/CustomIconPage')
const B2BContactPage = () => import(/* webpackChunkName: "b2b-contact--page" */ '@/views/B2BContactPage')
const SignatureLoginPage = () => import(/* webpackChunkName: "signature-login-page" */ '@/views/SignatureLoginPage.vue')
const FrameRedirectPage = () => import(/* webpackChunkName: "public-api-redirect-page" */ '@/views/FrameRedirectPage')

const onlyOnceRequest = {
  backDownload: false,
}

const routerNavigationDuplicatedCatchCases = ['push', 'replace']
routerNavigationDuplicatedCatchCases.forEach(method => {
  const originalMethod = Router.prototype[method]
  Router.prototype[method] = function m(location) {
    return originalMethod.call(this, location).catch(error => {
      if (
        !isNavigationFailure(error, NavigationFailureType.redirected) &&
        !isNavigationFailure(error, NavigationFailureType.duplicated) &&
        !isNavigationFailure(error, NavigationFailureType.cancelled)
      ) {
        throw error
      }
    })
  }
})

Vue.use(Router)

const router = new Router({
  mode: 'history',
  base: process.env.BASE_URL,
  routes: [
    {
      path: '/signature-login',
      component: SignatureLoginPage,
      meta: {noauth: true},
      props: route => ({
        timestamp: route.query.timestamp,
        signature: route.query.signature,
        company: route.query.company,
      }),
    },
    {
      path: '/:lang',
      component: EmptyComponent,
      children: [
        {
          path: '/',
          redirect: '/:lang/login',
        },
        ...(process.env.NODE_ENV !== 'development'
          ? []
          : [
              {
                path: 'customIcon',
                component: CustomIconPage,
                meta: {noauth: true},
              },
            ]),
        {
          path: 'editor/:projectId?',
          component: EditorCreate,
          children: [
            {
              path: '/',
              name: 'editor',
              component: EditorContainer,
              props: route => ({
                splitter: route.query.splitter,
                projectId: route.params.projectId,
              }),
              children: [
                {
                  path: 'character-casting',
                  component: ActorSelectionModal,
                  name: 'audioActorSelectionModal',
                  props: route => ({
                    projectId: route.params.projectId,
                    type: route.query.type,
                    changeActorIndex: route.query.actor,
                    mediaType: 'editor',
                    actorByFeature: route.query.actorByFeature,
                    tabName: route.query.tabName,
                  }),
                },
              ],
            },
          ],
        },
        {
          path: 'login',
          name: 'login',
          component: NewLoginPage,
          props: route => ({
            plan_url: route.query.plan_url,
            token: route.query.token,
            nextPath: route.query.nextPath,
          }),
          meta: {noauth: true},
        },
        {
          path: 'register',
          component: RegisterPage,
          props: route => ({
            plan_url: route.query.plan_url,
            token: route.query.token,
          }),
          meta: {noauth: true},
        },
        {
          path: 'anonymous-audio-editor',
          component: EditorContainer,
          props: route => ({
            category: route.query.category,
            projectId: route.query.category, // 없으면 error나서 임시로 넣음
          }),
          meta: {noauth: true, anonymous: true},
          children: [
            {
              path: 'character-casting',
              component: ActorSelectionModal,
              name: 'audioActorSelectionModal_anonymous',
              props: route => ({
                projectId: route.query.category,
                type: route.query.type,
                changeActorIndex: route.query.actor,
                mediaType: 'editor',
                actorByFeature: route.query.actorByFeature,
                tabName: route.query.tabName,
              }),
            },
          ],
        },
        // --------------------------------------------
        // v3 path
        // start
        // --------------------------------------------
        {
          path: 'dashboard',
          component: Dashboard,
          children: [
            {
              path: '/',
              component: PortalPage,
              props: route => ({
                actor_id: route.query.actor_id,
              }),
              name: 'dashboard',
            },
            {
              path: 'my-project/:folderId?',
              component: MyProjectPage,
              name: 'MyStudio',
              props: true,
            },
            {
              path: 'share',
              component: SharedProjectPage,
            },
            {
              path: 'download-history',
              component: PlanDownloadHistoryPage,
              redirect: 'download-history/video',
              children: [
                {path: 'video', name: 'videos'},
                {path: 'audio', name: 'audios'},
              ],
            },
            {
              path: 'settings',
              component: SettingsPage,
              redirect: 'settings/general',
              children: [
                {
                  path: 'general',
                  component: SettingsGeneralPage,
                },
                {
                  path: 'account',
                  component: SettingsAccountPage,
                },
                {
                  path: 'members',
                  component: SettingsMembersPage,
                },
                {
                  path: 'youtube',
                  component: SettingsYoutubePage,
                },
                {
                  path: 'ip-whitelists',
                  component: SettingsIpWhitelistsPage,
                },
                {
                  path: 'data-retention',
                  component: SettingsDataRetentionPage,
                },
              ],
            },
            {
              path: 'plan',
              component: PlanPage,
              redirect: 'plan/subscription',
              children: [
                {
                  name: 'subscription',
                  path: 'subscription',
                  component: PlanSubscriptionPage,
                  props: route => ({
                    mount: route.query.mount,
                    errorMsg: route.query.error_msg,
                    successResult: route.query.imp_success,
                    orderId: route.query.order_id,
                    pgToken: route.query.pg_token,
                    packageId: route.query.packageId,
                    couponId: route.query.couponId,
                  }),
                },
                {
                  path: 'invoices',
                  component: PlanInvoicesPage,
                },
                {
                  path: 'block',
                  component: PlanPaymentBlockPage,
                },
              ],
            },
            {
              path: 'sample-templates',
              component: SampleTemplatesPage,
            },
            {
              path: 'tutorial',
              component: TutorialPage,
            },
            {
              path: 'plan/purchase',
              component: PrepaidPlanPurchasePage,
              props: route => ({code: route.query.code}),
            },
          ],
        },
        {
          path: 'inactivation',
          component: InactivationPage,
        },
        {
          path: 'secession',
          component: SecessionPage,
        },
        {
          path: 'b2b-contact',
          component: B2BContactPage,
        },
        {
          path: 'secession-result',
          component: SecessionResultPage,
          meta: {noauth: true},
          props: route => ({
            name: route.query.name,
          }),
        },
        {
          path: 'public-api-character-casting',
          component: ActorSelectionModal,
          name: 'PublicApiActorSelectionModal',
          props: route => ({
            isPublicApi: Boolean(route.query.is_public_api),
            publicApiSelectType: route.query.public_api_select_type,
            publicApiSelectedActors: route.query.public_api_selected_actors,
          }),
        },
        {
          path: 'public-api-redirect',
          component: FrameRedirectPage,
          name: 'PublicApiFrameRedirectPage',
          meta: {noauth: true},
        },
      ],
    },
  ],
  scrollBehavior(to, from, savedPosition) {
    if (savedPosition) {
      return savedPosition
    }
    if (to.path.includes('/use-case') && from.path.includes('/use-case')) {
      return
    }
    if (to.path.includes('/membership') && from.path.includes('/membership')) {
      return
    }
    return {x: 0, y: 0}
  },
})

function changeLocale(locale) {
  i18n.locale = locale
  localStorage.setItem('lang', locale)
  intercomManager.setLanguage(locale)
}

function redirectToLocaleUrl(to, next) {
  // i18n.locale 은 window.open 시 항상 en으로 됨.
  // 따라서 redirect 처리 시 localStorage값으로 이용하게 변경
  const locale = localStorage.getItem('lang') || i18n.locale
  const targetUrl = to.redirectedFrom || to.path
  const from = targetUrl.split('?')[0]
  const redirectUrl = `/${locale}/${from.replace(/^\//, '')}`
  const resolvedRouter = router.resolve({
    path: redirectUrl,
    query: to.query,
  })
  if (resolvedRouter.resolved.matched.length > 0) {
    next({
      path: redirectUrl,
      query: to.query,
    })
  } else {
    next({
      path: `/${locale}`,
      query: to.query,
    })
  }
}
router.onReady(() => {
  const locale = localStorage.getItem('lang') || i18n.locale
  initCookieScript(locale)
})

// INFO: auth check
// 비로그인 사용자가 직접 url을 입력해서 접근 한다면 auth guard에 의해서 redirect 처리됨

// locale이 필요없는 URL을 정의
const LANGUAGE_EXCLUDE_URL = ['/signature-login']
router.beforeEach((to, from, next) => {
  const tokenExist = Vue.prototype.$auth.isAccessTokenExist()
  const isAnonymousToken = Vue.prototype.$auth.isAnonymous()

  if (!LANGUAGE_EXCLUDE_URL.includes(to.path)) {
    const lang = to.params.lang
    const languageList = [...Object.values(LANGUAGE)]
    if (languageList.includes(lang)) {
      loadLanguageAsync(lang)
      changeLocale(lang)
    } else {
      redirectToLocaleUrl(to, next)
      return
    }
  }

  if (Vue.prototype.$existSpinner()) {
    Vue.prototype.$hideSpinner()
  }

  const tokenRequiredPage = !to.matched.some(route => route.meta.noauth)
  const anonymousNotAllowedPage = !to.matched.some(route => route.meta.anonymous)

  if (tokenRequiredPage && tokenExist) {
    if (!onlyOnceRequest.backDownload && !Vue.prototype.$auth.isAnonymous()) {
      onlyOnceRequest.backDownload = true
      setTimeout(() => useBackDownloadStore().fetchBackDownload(Vue.prototype.$http), 0)
    }
  } else {
    if (onlyOnceRequest.backDownload) {
      onlyOnceRequest.backDownload = false
      useBackDownloadStore().$reset()
    }
  }
  if (
    (tokenRequiredPage && !tokenExist) ||
    (tokenRequiredPage && anonymousNotAllowedPage && tokenExist && isAnonymousToken)
  ) {
    const locale = localStorage.getItem('lang') || i18n.locale
    const nextPath = to.fullPath || to.query.nextPath

    next({
      path: `/${locale}/login`,
      query: {nextPath},
    })
  } else {
    next()
  }
})

export default router

export const isAnonymousPage = () => {
  return router.currentRoute.path.includes('anonymous-audio-editor')
}
