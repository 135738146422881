/**
 *
 * @param {string} cname cookie name
 * @param {string} cvalue cookie value
 * @param {Object} setCookieOptions 쿠키 설정 옵션
 * @param {Object} setCookieOptions.sameSite sameSite true 시 strict 적용
 * @param {Object} setCookieOptions.maxAge cookie max-age
 * @param {Object} setCookieOptions.isSecure domain secure 옵션
 */
export function setCookie(cname, cvalue, setCookieOptions = {}) {
  const d = new Date()
  d.setTime(d.getTime() + 7300 * 24 * 60 * 60 * 1000)
  const expires = 'expires=' + d.toUTCString()
  let cookieItem = `${cname}=${cvalue};${expires};${setCookieOptions.sameSite ? 'SameSite=Strict;' : ''}${
    setCookieOptions.maxAge ? `max-age=${setCookieOptions.maxAge};` : ''
  }path=/;`

  if (setCookieOptions.isSecure) {
    const isLocalhost = location.hostname === 'localhost' || location.hostname === '127.0.0.1'
    const isProduction = process.env.NODE_ENV === 'production'
    const isStaging = process.env.NODE_ENV === 'staging'
    let secureDomain = ''
    if (isProduction || isStaging) {
      // Live
      secureDomain = `domain=typecast.ai; secure;`
    } else if (isLocalhost) {
      // Localhost
      secureDomain = `domain=localhost;`
    } else {
      // Development
      secureDomain = `domain=icepeak.ai; secure;`
    }
    cookieItem = `${cookieItem} ${secureDomain}`
  }
  document.cookie = cookieItem
}

export function getCookie(cname) {
  const name = cname + '='
  const decodedCookie = decodeURIComponent(document.cookie)
  const ca = decodedCookie.split(';')
  for (let i = 0; i < ca.length; i++) {
    let c = ca[i]
    while (c.charAt(0) === ' ') {
      c = c.substring(1)
    }
    if (c.indexOf(name) === 0) {
      return c.substring(name.length, c.length)
    }
  }
  return ''
}

export function deleteCookie(cname) {
  if (getCookie(cname)) {
    const hostname = window.location.hostname
    const domain = hostname.split('.').slice(-2).join('.')
    document.cookie = `${cname}=; Path=/; Expires=Thu, 01 Jan 1970 00:00:01 GMT`
    document.cookie = `${cname}=; domain=${domain}; Path=/; secure; SameSite=Strict; Expires=Thu, 01 Jan 1970 00:00:01 GMT`
  }
}

export function neverShowTipModal(cname) {
  const tipModal = getCookie(cname)
  return tipModal !== ''
}

export function isClickedSurvey(cname) {
  const isClicked = getCookie(cname)
  return !!isClicked
}

export function isClickedNotSeeingNotice(noticeName) {
  const isClicked = getCookie(noticeName)
  return !!isClicked
}

export function isIgnoreDoubleQuote(cname) {
  const isSplit = getCookie(cname)
  return isSplit === 'true'
}
