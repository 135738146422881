// Polyfill
import './plugins/polyfill//urlSearchParamPolyfill'
import './plugins/polyfill/requestIdleCallbackPolyfill'
import './plugins/polyfill/flatmapPolyfill'

import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import Notifications from 'vue-notification'
import config from '@/config/config'
import VueFacebookPixel from 'vue-analytics-facebook-pixel'
import {ValidationObserver, ValidationProvider, extend} from 'vee-validate'
import * as rules from 'vee-validate/dist/rules'
import modal from './modal'
import spinner from './spinner'
import VTooltip from 'v-tooltip'
import VFloatingTooltip from '@/directives/floatingTooltip'
import VueTheMask from 'vue-the-mask'
import {i18n} from './i18n'

import {VueEventPlugin} from './utils/vue-ns-event'
import NaverEventPlugin from './utils/naver-event'
import clickOutsideDirective from '@/directives/click-outside'
import lowercase from '@/directives/lowercase'
import TypecastUI from 'typecast-ui'
import 'typecast-ui/src/styles/css/fontello.css'
import 'typecast-ui/src/styles/scss/imports.scss'
import TypecastSDK from './install'
import {loadStripe} from '@stripe/stripe-js'
import VueGtag, {addRoutesTracker} from 'vue-gtag'
import Hotjar from 'vue-hotjar'
import backlink from './backlink'
import growthbookPlugin from '@/libs/modules/growthbook'
import mixpanelPlugin from '@/libs/modules/mixpanel'
import mixpanelDatahouse from '@/libs/modules/mixpanelDatahouse'
import {deleteCookie} from '@/utils/cookieManager'
import CONSTANTS from '@/config/constants'

import {createPinia, PiniaVuePlugin} from 'pinia'
import {WatchPiniaPlugin} from 'store/plugins/WatchPiniaPlugin'
import {StateHashPiniaPlugin} from 'store/plugins/StateHashPiniaPlugin'
import {OnPiniaMountedPlugin} from 'store/plugins/OnPiniaMountedPlugin'

import bootstrapInit from '@/plugins/bootstrap'
import DatadogPlugin from '@/plugins/datadog'
import SentryPlugin from '@/plugins/sentry'
import jwt_decode from 'jwt-decode'

import {FirebaseManager, AuthPopupManager} from '@neosapience/typecast-auth-sdk'

import './tailwind.init.css'

Vue.use(spinner)
Vue.use(modal)
Vue.use(VueTheMask)
let app = ''

// Vue.component('v-popover', VPopover)
// Vue.directive('close-popover', VClosePopover)
Vue.use(VTooltip, {
  defaultPlacement: 'bottom',
  defaultTemplate:
    '<div class="tooltip" role="tooltip"><div class="tooltip-arrow typecast-tooltip-arrow"></div><div class="tooltip-inner typecast-tooltip-inner"></div></div>',
  defaultTrigger: window.innerWidth > 768 ? 'hover focus click' : 'click',
})

Vue.directive('floatingTooltip', VFloatingTooltip)
Vue.config.productionTip = false
Vue.use(Notifications)

Vue.use(Hotjar, {
  id: '2356889',
  isProduction: process.env.NODE_ENV === 'production',
})

Vue.use(
  VueGtag,
  {
    config: {
      id: 'G-5VCDJ4W4LQ',
      params: {
        send_page_view: true,
      },
    },
    // bootstrap is inlined in index.html.
    bootstrap: false,
  },
  router,
)
// add gtag tracking to router
addRoutesTracker()

Vue.use(VueFacebookPixel, {
  // debug: process.env.NODE_ENV !== 'production',
  router,
})

// Install VeeValidate rules and localization
Object.keys(rules).forEach(rule => {
  extend(rule, rules[rule])
})

// Install VeeValidate components globally
Vue.component('ValidationObserver', ValidationObserver)
Vue.component('ValidationProvider', ValidationProvider)
bootstrapInit(Vue)

Vue.use(DatadogPlugin)
Vue.use(SentryPlugin)
Vue.use(TypecastUI)
Vue.use(backlink)

FirebaseManager.init(process.env.NODE_ENV)
AuthPopupManager.init()
Vue.analytics.fbq.init(config.fb.code, {})

// eslint-disable-next-line new-cap
// Vue.prototype.$auth = VueAuthPlugin(Vue.prototype.$http)

Vue.use(VueEventPlugin, {
  $analytics: Vue.analytics,
  $gtag: Vue.$gtag,
  $store: store,
})

function initNaverEventPlugin() {
  const scriptTag = document.createElement('script')
  scriptTag.src = '//wcs.naver.net/wcslog.js'
  scriptTag.defer = true
  document.body.appendChild(scriptTag)
  scriptTag.onload = () => {
    Vue.use(NaverEventPlugin, {
      env: process.env.VUE_APP_API_URL,
    })
  }
  scriptTag.onerror = () => {
    Vue.use(NaverEventPlugin, {err: true})
  }
}

Vue.use(PiniaVuePlugin)
const pinia = createPinia()
pinia.use(WatchPiniaPlugin)
pinia.use(StateHashPiniaPlugin)
pinia.use(OnPiniaMountedPlugin)

Vue.use(TypecastSDK, {
  store: store,
  env: process.env.NODE_ENV,
  router: router,
})
Vue.directive('click-outside', clickOutsideDirective)
Vue.directive('lowercase', lowercase)

// NOTE: Firebase User 정보를 런타임 상황에서 체크 하기 위해서 사용
let previousUserUid = null

FirebaseManager.onAuthStateChanged(user => {
  if (user) {
    const parsedToken = jwt_decode(user.accessToken)
    // accessToken에 _id가 있으면 custom token이고 그대로 사용하면 됨.
    if (parsedToken._id) {
      Vue.prototype.$auth.setAccessToken(user.accessToken)
    }
    if (user.providerData.length > 0 && user.providerData[0].providerId) {
      store.commit('typecast/user/setSocialType', user.providerData[0].providerId)
      Vue.prototype.$auth.proactiveAuth.init(user.stsTokenManager.expirationTime)
    }
    Vue.prototype.$nsEvent.event({category: 'after_login', action: 'login_auth_change'})
    if (previousUserUid !== user.uid) {
      previousUserUid = user.uid
      if (app) {
        const isAnonymousAudioEditor = router.currentRoute.path.endsWith('/anonymous-audio-editor')
        if (router.currentRoute.meta.noauth && !isAnonymousAudioEditor) {
          router.replace('/dashboard')
        } else if (!isAnonymousAudioEditor) {
          window.location.reload()
        }
      }
    }
  } else {
    resetStorages()
    if (Vue.prototype.$auth.isAccessTokenExist()) {
      Vue.prototype.$auth.cleanAccessToken()
      Vue.prototype.$auth.proactiveAuth.cancelTimer()
    }
    // 현재 path가 null, login, root 가 아닌 나머지 경우에
    // login으로 리디렉션(예: 멀티탭에서 로그인인 경우, 로그아웃한 탭 이외에 다른 탭은 경로를 이곳에서 바꿔 줘야함)
    if (
      router.currentRoute.name !== null &&
      router.currentRoute.name !== 'login' &&
      router.currentRoute.name !== 'root'
    ) {
      window.location.assign('/login')
    }
  }

  if (!app) {
    app = new Vue({
      router,
      store,
      i18n,
      pinia,
      render: h => h(App),
    }).$mount('#app')
  }
})

// ie11 startsWith Polyfill
if (!String.prototype.startsWith) {
  String.prototype.startsWith = function (searchString, position) {
    position = position || 0
    return this.substr(position, searchString.length) === searchString
  }
}

async function setStripe() {
  try {
    const stripe = await loadStripe(config.stripe.apiKey)
    Vue.prototype.$stripe = Vue.$stripe = stripe
  } catch (error) {
    Vue.prototype.$stripe = Vue.$stripe = null
    console.error(error)
  }
}

function resetStorages() {
  const {CURRENT_COUPON, CLOSE_COUPON} = CONSTANTS.COOKIE_KEY_COUPON
  deleteCookie(CURRENT_COUPON)
  deleteCookie(CLOSE_COUPON)
}

Vue.use(growthbookPlugin)
Vue.use(mixpanelPlugin)
Vue.use(mixpanelDatahouse, {store, i18n})

setStripe()
initNaverEventPlugin()
