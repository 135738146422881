import jwt_decode from 'jwt-decode'

const VueAuthStore = {
  /**
   * 소셜 플랫폼 링크
   * @param {String} auth provider info (accessToken, refreshToken, provider)
   * @param {String} user user's phone info (country_phone_code, phone)
   */
  requestSocialLink(auth, user) {
    const {accessToken: access_token, refreshToken: refresh_token, provider} = auth
    const _user = user && {country_phone_code: user.countryPhoneCode, phone: user.phone}
    const url = '/api/auth/social-platform'
    return this.$http
      .post(url, {
        auth: {
          provider,
          access_token,
          refresh_token,
        },
        user: _user,
      })
      .then(res => res.data.result)
  },
  /**
   *
   * @param {String} payload info for request social platform tokens
   */
  requestSocialToken(payload) {
    const {provider, code, state, redirectUri} = payload
    const url = '/api/auth/social-platform/token'
    return this.$http
      .post(url, {
        provider,
        code,
        state,
        redirect_uri: redirectUri,
      })
      .then(res => res.data.result)
  },
  requestSocialMe(accessToken, provider) {
    const url = '/api/auth/social-platform/me'
    return this.$http
      .post(url, {
        provider,
        access_token: accessToken,
      })
      .then(res => res.data.result)
  },
  isAnonymous() {
    const token = this.getAccessToken()
    if (!token) {
      return false
    }
    const decoded = jwt_decode(token)
    return decoded.authtype === 'anonymous'
  },
  isAccessTokenExist() {
    return !!this.$http.defaults.headers.common.Authorization
  },
  setAccessToken(token) {
    this.$http.defaults.headers.common['Authorization'] = `Bearer ${token}`
  },
  getAccessToken() {
    return this.$http.defaults.headers.common.Authorization?.replace('Bearer ', '')
  },
  cleanAccessToken() {
    delete this.$http.defaults.headers.common.Authorization
  },
  fbCustomToken(idToken) {
    const url = `/api/auth-fb/custom-token`
    return this.$http.post(url, {token: idToken}).then(response => {
      return response.data.result
    })
  },
  fbCustomTokenUsingSignature({signature, timestamp, company}) {
    const url = `/api/auth-fb/custom-token`
    return this.$http.post(url, {signature, timestamp, company}).then(response => {
      return response.data.result
    })
  },
  fbGetEmail(email) {
    const url = `/api/auth-fb/email`
    return this.$http.get(url, {params: {email}}).then(response => {
      return response.data.result
    })
  },
  getProviderByEmail({email}) {
    const url = `/api/auth-fb/v2/provider`
    return this.$http
      .get(url, {
        params: {
          email,
        },
      })
      .then(res => res.data.result)
  },
  signupAnonymous({language}) {
    const url = `/api/auth-fb/v3/signup/anonymous`
    return this.$http.post(url, {language}).then(res => res.data.result)
  },
  sendPhoneNumberVerificationCode(payload) {
    const {countryCode, countryPhoneCode, phone} = payload

    const url = `/api/auth/phone/request-code`
    return this.$http
      .post(url, {country_code: countryCode, country_phone_code: countryPhoneCode, phone})
      .then(res => res.data.result)
  },
  confirmPhoneNumberVerificationCode(payload) {
    const {code, verificationId} = payload

    const url = `/api/auth/phone/verify-code`
    return this.$http.post(url, {twilio_verification_id: verificationId, code: code}).then(res => res.data.result)
  },
}

export default function ($http) {
  VueAuthStore.$http = $http
  return VueAuthStore
}
