export const NORMAL_1 = 'normal-1';
const CONSTANTS = {
    LANG: 'auto',
    AUTO_LANGS: ['en-us', 'ko-kr'],
    DELIMITER_SET: ['"', '.', '!', '?', '｡', '。'],
    MAX_SECONDS: 60,
    MAX_QUERY_LENGTH: 300,
    CUSTOM_SPEED: 1.6,
    DEFAULT_SPEED: 1,
    DEFAULT_TEMPO: 1,
    DEFAULT_SILENCE_SEC: 0.1,
    DEFAULT_SILENCE_MILLI_SEC: 100,
    NATURALNESS: 0.8,
    CACHE_STRIDE: 4,
    SPEAK_MODE: 'one-vocoder',
    QUERY_CLASS_DONE: 'play-done',
    QUERY_CLASS_PROGRESS: 'play-progress',
    QUERY_CLASS_FAILED: 'play-failed',
    QUERY_CLASS_BLINK: 'play-blink',
    QUERY_CLASS_PLAYING: 'playing',
    KOR_REGEXR: /[가-힣ㄱ-ㅎㅏ-ㅣ]/gi,
    KOR_VOWEL_REGEXR: /[ㄱ-ㅎ]/gi,
    ENG_REGEXR: /[A-Za-z]/gi,
    NUMBER_REGEXR: /[0-9]/gi,
    ENG_ESP_DE_REGEXR: /[A-Za-zÀ-ÿäöüÄÖÜß]/gi,
    JPN_REGEXR: /[\u3040-\u309f\u30a0-\u30ff\uff66-\uff9f\u4e00-\u9faf\u3005\u30F6]/gi,
    STYLE_LABEL_LIST: {
        normal: {
            label: '일반',
        },
        sad: {
            label: '슬픔',
        },
        happy: {
            label: '기쁨',
        },
        angry: {
            label: '화남',
        },
        toneup: {
            label: '톤 업',
        },
        tonemid: {
            label: '중간 톤',
        },
        tonedown: {
            label: '톤 다운',
        },
        regret: {
            label: '후회',
        },
        urgent: {
            label: '긴장',
        },
        whisper: {
            label: '속삭임',
        },
        scream: {
            label: '절규',
        },
        shout: {
            label: '외침',
        },
        trustful: {
            label: '신뢰',
        },
        soft: {
            label: '부드러운',
        },
        cold: {
            label: '차가운',
        },
        sarcasm: {
            label: '놀림',
        },
        inspire: {
            label: '설득',
        },
        cute: {
            label: '애교',
        },
        cheer: {
            label: '응원',
        },
        hitune: {
            label: '하이튠',
        },
        lowtune: {
            label: '로우튠',
        },
        tunelv1: {
            label: '튠Lv1',
        },
        tunelv2: {
            label: '튠Lv2',
        },
        casual: {
            label: '대화체',
        },
    },
    OPTION_LIST: {
        MOVE: { NAME: 'move', ICON_NAME: 'IconFolderMove', OPTION_NAME: 'showMoveToFolderModal' },
        RENAME: { NAME: 'rename', ICON_NAME: 'IconEdit', OPTION_NAME: 'showRenameModal' },
        DUPLICATE: { NAME: 'duplicate', ICON_NAME: 'IconDuplicate', OPTION_NAME: 'handleDuplicateProject' },
        SHARE: { NAME: 'share', ICON_NAME: 'IconSharePlus', OPTION_NAME: 'showShareModal' },
        DELETE: { NAME: 'delete', ICON_NAME: 'IconTrash', OPTION_NAME: 'showDeleteModal' },
    },
    PROVIDER: {
        GOOGLE: 'google.com',
        FACEBOOK: 'facebook.com',
        PASSWORD: 'password',
        KAKAO: 'kakao.com',
        NAVER: 'naver.com',
    },
    DEFAULT_PLAN_TERM: 'yearly',
    ANOTHER_PLAN_TERM: 'monthly',
    PLANS: ['free_id', 'basic_id', 'pro_id', 'pro_plus_id', 'team_id', 'business_id', 'enterprise_id'],
    PLAN_ICON: {
        free_id: 'IconFreePlan',
        basic_id: 'IconBasicPlan',
        pro_id: 'IconProPlan',
        business_id: 'IconBusinessPlan',
        enterprise_id: 'IconEnterprisePlan',
        v2_free_id: 'IconFreePlanV2',
        v2_basic_id: 'IconBasicPlanV2',
        v2_pro_id: 'IconProPlanV2',
        v2_pro_plus_id: 'IconProPlusPlanV2',
        v2_team_id: 'IconProTeamPlanV2',
    },
    NOT_MONTHLY_PLANS: ['free_id', 'enterprise_id'],
    PROJECT_ROUTE_LIST: ['my-project', 'share'],
    MY_PROJECT_ROUTE: 'my-project',
    SHARE_PROJECT_ROUTE: 'share',
    EDITOR_EXCLUDE_SAVE_BEFORE_ROUTE: ['pending', 'login', 'register', 'anonymous-audio-editor'],
    THREAD_STATUS: {
        OPEN: 'open',
        CLOSED: 'closed',
    },
    TIMELINE_BAR_WIDTH_PER_SEC: 30,
    PROJECT_NAME_MAX_LENGTH: 130,
    VIDEO_MAX_SLIDE_DURATION: 60 * 2,
    VIDEO_MAX_DURATION: 60 * 30,
    ERROR_NEED_TO_BE_PARSED: ['stripe_unknown_error', 'typecast_error', 'iamport_http_error', 'iamport_response_error'],
    DIFF_CONDITION_LIST: {
        business_id: {
            pro_id: [
                'business_user',
                'reduced_custom_voice',
                'add_custom_voice',
                'download_time',
                'no_add_time',
                'no_share_project',
                'no_comment',
                'no_member',
            ],
            basic_id: [
                'business_user_to_pro',
                'add_custom_voice',
                'custom_voice',
                'download_time',
                'video_hd',
                'audio_16k',
                'no_inspector',
                'no_add_time',
                'no_share_project',
                'no_comment',
                'no_member',
            ],
        },
        pro_id: {
            basic_id: ['pro_plan_user', 'custom_voice', 'download_time', 'video_hd', 'audio_16k', 'no_inspector'],
        },
        basic_id: {
            free_id: ['download_time', 'no_virtual_actor', 'video_sd', 'partial_actor', 'no_import'],
        },
    },
    PLAN_CONDITION_LIST: {
        free_id: ['free_plan_user', 'download_time', 'video_sd', 'audio_16', 'partial_actor'],
        basic_id: ['basic_plan_user', 'download_time', 'video_hd', 'audio_16', 'all_actor', 'import'],
        pro_id: [
            'pro_plan_user',
            'download_time',
            'custom_voice',
            'video_fhd',
            'audio_44',
            'all_actor',
            'inspector',
            'import',
        ],
        business_id: [
            'business_plan_user',
            'download_time',
            'custom_voice',
            'add_custom_voice',
            'video_fhd',
            'audio_44',
            'all_actor',
            'inspector',
            'import',
            'add_time',
            'share_project',
            'comment',
            'add_member',
        ],
    },
    DOWNLOAD_RECOMMENDED_DURATION: 60 * 45,
    DOWNLOAD_MAX_DURATION: 60 * 60,
    PREVIEW_QUALITY: {
        HIGH: '32k',
        LOW: '8k',
        NORMAL: '16k',
    },
    PAYMENT_METHOD: {
        STRIPE: 'stripe',
        KAKAOPAY: 'kakaopay',
        KAKAOPAY_DIRECT: 'kakaopaydirect',
        NICE: 'nice',
        TOSS: 'toss',
        CORPORATION_CARD: 'corporation_card',
        GOOGLE: 'google',
    },
    PAYMENT_PLATFORM: {
        STRIPE: 'stripe',
        IAMPORT: 'iamport',
        TOSS: 'toss',
        KAKAOPAY: 'kakaopay',
    },
    COLOR: {
        '#ffffff': 'white',
        '#000000': 'black',
        '#c2d8e7': 'lightblue',
        '#787991': 'darkblue',
        '#f4ccaa': 'lightorange',
        '#6ed4d8': 'softcyan',
        '#6ec574': 'chromakey',
        '#00000000': 'transparent',
    },
    TRANSITION: {
        fade: 'IconFade',
        fadeblack: 'IconFadeBlack',
        fadewhite: 'IconFadeWhite',
        smoothup: 'IconSmoothUp',
        smoothdown: 'IconSmoothDown',
        smoothright: 'IconSmoothRight',
        smoothleft: 'IconSmoothLeft',
    },
    SHORT_CUT_KEY_MAP: [
        {
            header: 'keyboard_shortcut.character_change',
            content: {
                macOS: 'cmd + option + 1 ~ 0',
                windows: 'Ctrl + Shift + 1 ~ 0',
            },
        },
        {
            header: 'keyboard_shortcut.toggle_play',
            content: {
                macOS: 'cmd + option + Enter',
                windows: 'Ctrl + Shift + Enter',
            },
        },
        {
            header: 'keyboard_shortcut.prev_sentence',
            content: {
                macOS: 'cmd + option + ,',
                windows: 'Ctrl + Shift + <',
            },
        },
        {
            header: 'keyboard_shortcut.next_sentence',
            content: {
                macOS: 'cmd + option + .',
                windows: 'Ctrl + Shift + >',
            },
        },
    ],
    ACTOR_ORDER_PRIORITY: {
        TOP: 'order-top',
        MID: 'order-mid',
        LOW: 'order-low',
    },
    ACTOR_FILTER_OPTION: {
        TRIAL: 'trial',
        VIRTUAL_HUMAN: 'virtual_human',
        ANIMATION: 'animation',
        ENGLISH: 'en-us',
        KOREAN: 'ko-kr',
        JAPANESE: 'ja-jp',
        SPANISH: 'es-es',
        GERMAN: 'de-de',
        FRENCH: 'fr-fr',
        CHINESE: 'zh-cn',
        PORTUGUESE: 'pt-pt',
        ENGLISH_OTHERS: 'en-us_others',
        KOREAN_OTHERS: 'ko-kr_others',
        MALE: '남성',
        FEMALE: '여성',
        EMO: 'emo',
        TONE: 'tone',
        PROMPT: 'styletag',
        SPEAKING_DURATION: 'speaking-duration',
        LAST_PITCH: 'last-pitch',
        CHILD: '아동',
        TEENAGER: '청소년',
        YOUTH: '청년',
        MIDDLE_AGED: '중년',
        MATURE: '장년',
        DOCUMENTARY: '다큐/리뷰',
        AUDIOBOOK: '낭독/오디오북',
        ANNOUNCER: '기자/아나운서',
        RADIO: '라디오/팟캐스트',
        AD: '광고/이벤트',
        EDUCATION: '교육/강의',
        GUIDE: '안내음성/ARS',
        GAME: '게임/애니',
        MUSIC: '음악/엔터테인먼트',
        POWERFUL: '힘 있는',
        ENERGETIC: '밝은',
        CUTE: '귀여운',
        PLAYFUL: '가벼운',
        CLASSY: '세련된',
        WARM: '따뜻한',
        CALM: '차분한',
        TRUSTFUL: '신뢰감있는',
        COLD: '차가운',
        EMOTIONAL: '감성적인',
        ETC: '기타',
        CUSTOM: 'custom',
    },
    ACTOR_SORTING_TAB_TYPE: {
        DEFAULT: '_index',
        POPULAR: 'score',
        HISTORY: 'recent',
        BOOKMARK: 'bookmark',
    },
    PLAN_LIST: {
        FREE_ID: 'free_id',
        BASIC_ID: 'basic_id',
        PRO_ID: 'pro_id',
        BUSINESS_ID: 'business_id',
        PRO_PLUS_ID: 'pro_plus_id',
        TEAM_ID: 'team_id',
        ENTERPRISE_ID: 'enterprise_id',
    },
    PLAN_NAME: {
        free_id: 'Free',
        basic_id: 'Basic',
        pro_id: 'Pro',
        business_id: 'Business',
        enterprise_id: 'Enterprise',
    },
    TUTORIAL_MOTION_GUIDE_LIST: {
        EMOTION_TONE_PRESET: 'emotion_tone',
        EMOTION_TONE_PROMPT: 'emotion_tone_prompt',
        SPEECH_PACE_PRESET: 'speech_pace_preset',
        SPEAKING_DURATION: 'speaking_duration',
        PAUSE: 'pause',
        INTONATION: 'intonation',
        VIRTUAL_HUMAN: 'virtual_human',
        ANIMATION: 'animation',
        RETAKE: 'retake',
    },
    COACH_MARK_STEP: {
        STEP1: 'step1',
        STEP2: 'step2',
        STEP3: 'step3',
        STEP4: 'step4',
    },
    COACHMARK_KEY: {
        FIRST_EDITOR: 'isFirstEditor',
    },
    NEW_VISITOR_TEMPLATE_ID: {
        audio: {
            development: {
                en: '63a939c16d4310f1c2a5cc14',
                ko: '63a939a96d4310f1c2a5cc11',
            },
            production: {
                en: '63d0b71074190a31f3d4369b',
                ko: '63d0a9bdde65c7924596e5df',
            },
        },
        shorts: {
            development: {
                en: '63e098fbbd5095494b95f7ef',
                ko: '63e098fbbd5095494b95f7ef',
            },
            production: {
                en: '64421a01abd682c4af9a1347',
                ko: '63e323f8f6ba554fc56be099',
            },
        },
    },
    DENOTING_DETAIL: ['랩', '영국', '독일', '사투리', '남미'],
    COOKIE_KEY_COUPON: {
        CURRENT_COUPON: 'current_coupon',
        DO_NOT_SHOW_COUPON: 'do_not_show_coupon',
        CLOSE_COUPON: 'close_coupon',
    },
    DEFAULT_STYLE_NAME: NORMAL_1,
    DEFAULT_STYLE_TAGS: [0, '0', NORMAL_1],
    AUDIO_DOWNLOAD_CONFIG: {
        DOWNLOAD_RANGE: {
            ALL: 'all',
            SELECTED: 'selected',
        },
        MERGE_WAY: {
            WHOLE: 'whole',
            DIVIDED: 'divided',
        },
        FILE_TYPE: { MP3: 'mp3', WAV: 'wav' },
        AUDIO_QUALITY: { LOW: 'low', NORMAL: 'normal', HD1: 'hd1' },
    },
    NOTICE_BAR_KEY: {
        NO_MORE_RECOMMEND_BAR: 'noMoreRecommendBar',
    },
};
export default Object.freeze(CONSTANTS);
export const SHORTS_PROJECT_MEDIA_TYPE = 'shorts';
export const AUDIO_PROJECT_MEDIA_TYPE = 'audio';
export const VIDEO_DOM_WIDTH = 1920;
export const VIDEO_DOM_HEIGHT = 1080;
export const VIDEO_DOM_WIDTH_SHORTS = 1080;
export const VIDEO_DOM_HEIGHT_SHORTS = 1920;
export const BACKGROUND_DEFAULT_HEX = '#00000000';
export const BACKGROUND_CHROMAKEY_HEX = '#6ec574';
export const ASSET_MIN_DURATION = 1; // sec
export const ASSET_LOAD_STATUS = {
    LOADING: 'LOADING',
    UPLOADING: 'UPLOADING',
    PROCESSING: 'PROCESSING',
    DELETING: 'DELETING',
};
export const TYPE_BGM = 'bgm';
export const TYPE_BGM_FOR_UPLOAD = 'audio';
export const BGM_SEARCH_QUERY_INJECT_KEY = 'InspectorBgm__searchQuery';
export const BGM_LIST_COUNT = 100;
export const BGM_TAB_PAGE = {
    ALL: 'all',
    UPLOAD: 'upload',
    BOOKMARK: 'bookmark',
    RECENT: 'recent',
    RECOMMENDED: 'recommended',
    SEARCH: 'search',
};
export const ALLOW_VIDEO_FORMAT = ['mp4', 'webm'];
export const ALLOW_IMAGE_FORMAT = ['jpg', 'jpeg', 'png'];
export const ALLOW_IMAGE_AND_VIDEO_FORMAT = ALLOW_IMAGE_FORMAT.concat(ALLOW_VIDEO_FORMAT);
export const ALLOW_AUDIO_FORMAT = ['mp3', 'wav'];
export const TC_ACCESS_TOKEN = 'tc_access_token';
