import { config } from '@/config';
import { merge } from 'utils';
import { useVideoDomStore } from 'store/editor';
export const getSpeakTextObjectStyle = () => {
    const containerStyle = getSpeakTextContainerStyle();
    const childrenStyle = getSpeakTextChildrenStyle();
    const wrapperStyle = getWrapperStyle();
    return {
        containerStyle,
        childrenStyle,
        wrapperStyle,
    };
};
const defaultSpeakTextContainerStyle = {
    zIndex: 3000,
    textAlign: 'center',
    width: '100%',
    fontWeight: '400',
    color: '#fff',
    position: 'absolute',
    userSelect: 'none',
    pointerEvents: 'none',
};
const defaultSpeakTextChildrenStyle = {
    position: 'relative',
    borderRadius: '1.5rem',
    padding: '0.15em 0.6em',
    wordBreak: 'keep-all',
    overflowWrap: 'anywhere',
    whiteSpace: 'pre-wrap',
    display: 'inline-block',
    userSelect: 'none',
    pointerEvents: 'none',
    cursor: 'text',
};
const getSpeakTextContainerStyle = () => {
    const { subtitle } = useVideoDomStore();
    const { isShortsProject } = config;
    const { size, position } = subtitle;
    const sharedStateStyle = {
        // NOTE: 사이즈 측정 근거는 디자인에서 정의된 폰트 사이즈를 1920px 기준으로 계산한 값
        fontSize: size === 'small' ? '43px' : size === 'medium' ? '64px' : '86px',
        bottom: position === 'bottom' ? '10%' : 'initial',
        top: position === 'top' ? '10%' : position === 'middle' ? '50%' : 'initial',
        transform: position === 'middle' ? 'translateY(-50%)' : 'initial',
    };
    if (isShortsProject) {
        return merge(sharedStateStyle, defaultSpeakTextContainerStyle, {
            bottom: position === 'bottom' ? '312px' : 'initial',
            top: position === 'top'
                ? '212px'
                : position === 'middle'
                    ? '50%'
                    : 'initial',
        });
    }
    return merge(sharedStateStyle, defaultSpeakTextContainerStyle);
};
const getWrapperStyle = () => {
    const { subtitle } = useVideoDomStore();
    const { isShortsProject } = config;
    const { style: subtitleStyle } = subtitle;
    const wrapperStyle = {
        maxWidth: '80%',
        margin: '0 auto',
    };
    if (subtitleStyle === 'bg_wide') {
        wrapperStyle.width = '80%';
    }
    if (isShortsProject) {
        wrapperStyle.maxWidth = '68%';
    }
    return wrapperStyle;
};
// NOTE: 'Noto Color Emoji'는 video-dom frontend.scss에서 import
// NOTE: 'Tofu'는 video-dom fonts.scss에서 import
const FALLBACK_FONT_LIST = [
    'Noto Sans KR',
    'Noto Sans SC',
    'Noto Color Emoji',
    'Tofu',
];
const getSpeakTextChildrenStyle = () => {
    const { subtitle } = useVideoDomStore();
    const { fontFamily, style: subtitleStyle } = subtitle;
    const fontFamilyWithFallback = [fontFamily, ...FALLBACK_FONT_LIST].join(', ');
    const sharedStateStyle = {
        fontFamily: fontFamilyWithFallback,
        width: subtitleStyle === 'bg_wide' ? '100%' : 'auto',
        backgroundColor: subtitleStyle.startsWith('bg_')
            ? 'rgba(0, 0, 0, .6)'
            : 'transparent',
        textShadow: subtitleStyle === 'shadow' ? '2px 2px 4px rgba(0, 0, 0, 0.6)' : 'none',
        fontWeight: subtitleStyle === 'outline' ? 'bolder' : '400',
    };
    return merge(sharedStateStyle, defaultSpeakTextChildrenStyle);
};
